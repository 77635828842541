import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";

export default function AboutPage() {
  return (
    <Layout>
      <div className="columns-2 gap-x-4">
        <StaticImage src="../images/me.jpg" alt="Me" className="max-h-1/6" />
        <div>
          <h1>Hi!</h1>
          <p className="mb-2">
            Thanks for visiting my portfolio!
          </p>
          <p>
            I'm here to help capture your life's events. From a family photo to proposals, engagements and weddings.
          </p>
        </div>
      </div>
    </Layout>
  );
}
